import React from "react"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { HeroMini } from "components/hero"
import { Section } from "components/section"
import CookiePolicyContent from "components/policies/CookiePolicyContent"

const heroProps = {
  title: <>Cookie Policy</>,
}

const CookiePolicy = (props) => (
  <Layout location={props.location}>
    <Meta title="Cookie Policy" />
    <HeroMini {...heroProps} />
    <Section noTopSpacing>
      <CookiePolicyContent />
    </Section>
  </Layout>
)

export default CookiePolicy
