import React from "react"
import cx from "classnames"
import s from "./Styles.module.scss"
import { SectionHeading } from "../section"

const CookiePolicyContent = () => (
  <div className={s.wrapper}>
    <p className={s.paragraph}>
      We use technologies on the Website{" "}
      <a href="https://martian.ventures">martian.ventures</a> (the Website) to
      collect information that help us improve your online experience. In this
      Cookie Policy, we refer to these technologies, which include cookies,
      pixels, web beacons and gifs, or similar collectively as cookies (the
      cookies). This policy explains different types of cookies that are used on
      the Website and how you are able to control them. We may change this
      Cookie Policy at any time without notifying you. Please take a look at the
      last effective date at the bottom of this page to see when this Cookie
      Policy was last revised. Any changes in this Cookie Policy will become
      effective when we make the revised Cookie Policy available on or through
      the Website. Any personal information that we collect through the use of
      cookies is obtained through transparent notice to you and through your
      consent. Where applicable, we provide you with the opportunity to opt out.
      We hope that this Cookie Policy helps you understand, and feel more
      confident about our use of cookies. Please, also refer to our Privacy
      Policy to understand the other ways in which we use information we collect
      about you. If you have any further queries, please contact us at{" "}
      <a
        href="mailto:hello@martian.ventures"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@martian.ventures
      </a>
      .
    </p>
    <SectionHeading className={s.title} order="01">
      What is a cookie?
    </SectionHeading>
    <p className={s.paragraph}>
      A cookie (or cookies) is a small text file that is stored on your computer
      or mobile device. Cookies are widely used in order to make Websites work,
      or work in a better, more efficient way. They do this because Websites can
      read and write these files, enabling them to recognize you and remember
      important information that will make your use of a Website more convenient
      (e.g. by remembering your preferences).
    </p>
    <SectionHeading className={s.title} order="02">
      What kind of cookies do we use?
    </SectionHeading>
    <p className={s.paragraph}>
      Below is a list of the different types of cookies we may use on the
      Website.
    </p>

    <SectionHeading className={cx(s.title, s.titleWithBullet)}>
      Analytical cookies
    </SectionHeading>
    <p className={cx(s.paragraph, s.paragraphSmall)}>
      Performance cookies, which are sometimes called analytics cookies, collect
      information about your use of this Website and enable us to improve the
      way it works for the user. For example, performance cookies show us which
      are the most frequently visited pages on the Website, allow us to see the
      overall patterns of usage on the Website and help us record any
      difficulties users have with the Website.
    </p>
    <SectionHeading className={cx(s.title, s.titleWithBullet)}>
      Functionality Cookies
    </SectionHeading>
    <p className={cx(s.paragraph, s.paragraphSmall)}>
      In some circumstances, we may use functionality cookies. Functionality
      cookies allow us to remember the choices you make on our Website and to
      provide enhanced and more personalized features, such as customizing a
      certain webpage, remembering if we have asked you to participate in a
      promotion or subscription and for other services you request, like
      watching a video or commenting on a blog. All of these features help us to
      improve your visit to the Website. For instance, cookies could help us
      remember whether you have signed up for our newsletter and enhance your
      experience of viewing our Website by knowing so.
    </p>
    <SectionHeading className={cx(s.title, s.titleWithBullet)}>
      Marketing cookies
    </SectionHeading>
    <p className={cx(s.paragraph, s.paragraphSmall)}>
      We and our service providers may use targeting or advertising cookies to
      deliver ads that we believe are more relevant to you and your interests.
      Occasionally we use Facebook, Linkedin or Reddit advertising to measure,
      optimize campaigns and build custom audiences for better audience
      targeting.
    </p>

    <SectionHeading className={s.title} order="03">
      How do third parties use cookies on the Website?
    </SectionHeading>
    <p className={s.paragraph}>
      In some circumstances, we may work with third parties to provide services
      on our Website. Third-party organizations may use their own cookies to
      collect information about your activities on our Website. This information
      may be used by them to serve advertisements that they believe are most
      likely to be of interest to you based on content you have viewed.
      Third-party organizations may also use this information to measure the
      effectiveness of their advertisements. We do not control these cookies and
      to disable or reject third-party cookies, please refer to the relevant
      third party’s Website. You can also learn more about controlling cookies
      in the section below.
    </p>
    <SectionHeading className={s.title} order="04">
      How do I control cookies?
    </SectionHeading>
    <p className={s.paragraph}>
      As you visit our Website, you will be asked to accept our Cookies. At this
      point, you are able to customize the level of cookies you wish to accept
      (or refuse). Once you submit your preferences, you will not be asked again
      for as long as Cookies are stored at your device.
    </p>
    <p className={s.paragraph}>
      You also may refuse to accept cookies from this Website at any time by
      activating the setting on your browser which allows you to refuse cookies.
      Further information about the procedure to follow in order to disable
      cookies can be found on your Internet browser provider's Website via your
      help screen.
    </p>
    <p className={s.paragraph}>
      You may wish to refer to{" "}
      <a
        href="http://www.allaboutcookies.org/manage-cookies/index.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.allaboutcookies.org/manage-cookies/index.html
      </a>{" "}
      for information on commonly used browsers.
    </p>
    <p className={s.paragraph}>
      Please be aware that if cookies are disabled, not all features of the
      Website may operate as intended.
    </p>
    <SectionHeading className={s.title} order="05">
      List of used Cookies
    </SectionHeading>
    <p className={s.paragraph}>
      Below is a list of cookies that are currently used.
    </p>
    <div className={s.table}>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Category</td>
            <td>Purpose</td>
            <td>More info</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Google Analytics</td>
            <td>Analytical</td>
            <td>Helps us discover how our Website is being used.</td>
            <td>
              <a
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Analytics Cookies
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <a
                href="http://segment.io/"
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Segment.io
              </a>
            </td>
            <td>Analytical</td>
            <td>
              Segment enables the structured connection of the usage data
              arising from your visit to our website to the analysis and
              advertising services, provided you have given us your consent for
              those services.
            </td>
            <td>
              <a
                href="https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Segment Analytics.js API
              </a>
            </td>
          </tr>
          <tr>
            <td>M&amp;M Newsletter</td>
            <td>Functional</td>
            <td>
              Internal Website cookie that stores info about your Newsletter
              subscription status.
            </td>
            <td>
              For more info about this Cookie,{" "}
              <a
                href="mailto:hello@martian.ventures"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact us
              </a>
              .
            </td>
          </tr>
          <tr>
            <td>Facebook Pixel</td>
            <td>Marketing</td>
            <td>
              Facebook Pixel enables us to measure, optimize and build audiences
              for advertising campaigns served on Facebook. It lets us use
              advertising features like audiences, conversions, targeting, and
              optimization as well as Facebook Analytics data.
            </td>
            <td>
              <a
                href="https://www.facebook.com/policies/cookies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook cookie policy
              </a>
            </td>
          </tr>
          <tr>
            <td>Linkedin Pixel</td>
            <td>Marketing</td>
            <td>
              Linkedin Pixel enables us to measure, optimize and build audiences
              for advertising campaigns served on Linkedin. It lets us use
              advertising features like audiences, conversions, targeting and
              optimization.
            </td>
            <td>
              <a
                href="https://www.linkedin.com/legal/l/cookie-table"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn cookie table
              </a>
            </td>
          </tr>
          <tr>
            <td>Reddit Pixel</td>
            <td>Marketing</td>
            <td>
              Reddit Pixel enables us to measure, optimize and build audiences
              for advertising campaigns served on Reddit. It lets us use
              advertising features like audiences, conversions, targeting and
              optimization.
            </td>
            <td>
              <a
                href="https://www.redditinc.com/policies/cookie-notice"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reddit cookie notice
              </a>
            </td>
          </tr>
          <tr>
            <td>Hotjar</td>
            <td>Analytical</td>
            <td>
              Hotjar helps us improve our website by analyzing the user
              experience and journey.
            </td>
            <td>
              <a
                href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hotjar cookie information
              </a>
            </td>
          </tr>
          <tr>
            <td>Fullstory</td>
            <td>Analytical</td>
            <td>
              Fullstory helps us improve our website by analyzing the user
              experience and journey.
            </td>
            <td>
              <a
                href="https://help.fullstory.com/hc/en-us/articles/360020829513-Why-FullStory-uses-First-Party-Cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fullstory first-party cookies
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p className={cx(s.paragraph, s.paragraphSmall)}>
      This Policy was last updated in March 2021.
    </p>
  </div>
)

export default CookiePolicyContent
